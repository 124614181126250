const {listProducts} = require('/js/sections/products.js');

class Collection {
    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.collection = obj.collection;
        this.products = obj.products;
        this.link = obj.link;
        this.hidden = obj.hidden;
    }
}

class Product {
    constructor(obj) {
        this.id = obj.id;
        this.group = obj.group;
        this.groupId = obj.groupId;
        this.name = obj.name;
        this.collection = obj.collection;
        this.images = obj.images;
        this.link = obj.link;
        this.linkVK = obj.linkVK;
        this.hidden = obj.hidden;

        this.description = obj.description;
    }
}

function getProducts() {
    const toysRaw = listProducts.toys;
    // const dressRaw = listProducts.dress;
    // const printsRaw = listProducts.prints;

    const toys = toysRaw.map(((p, idx) => new Product({...p, groupId: 'toys', id: idx})));
    // const dress = dressRaw.map(((p, idx) => new Product({...p, id: idx})));
    // const prints = printsRaw.map(((p, idx) => new Product({...p, id: idx})));

    return {toys,};
}

exports.products = getProducts();

